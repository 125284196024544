<!--
 * @Description: PC头部菜单
 * @Autor: WangYuan
 * @Date: 2021-05-19 14:07:29
 * @LastEditors: WangYuan
 * @LastEditTime: 2021-12-15 17:09:06
-->
<template>
  <div>
    <div class="topBar">
      <!-- 左侧内容 -->
      <span class="f14 pointer" @click="back"></span>

      <!-- 右侧内容 -->
      <div class="topBar-right">
        <span>编辑配置：</span>
        <el-select v-model="currProject" placeholder="请选择" size="small" style="margin: 0 10px;" @change="onChange">
          <el-option
            v-for="item in getProjectList"
            :key="item.project_id"
            :label="item.project_id"
            :value="item.project_id">
          </el-option>
        </el-select>
        <el-button size="small f-white bg-theme" @click="isShow = true">设置默认配置</el-button>
        <el-button size="small f-white bg-theme" @click="openSave">保存</el-button>
        <!-- <el-button size="small ml10" @click="onLogout">退出</el-button> -->
      </div>
    </div>

    <real-timeView :show.sync="show"></real-timeView>

    <save-dialog ref="save"></save-dialog>

    <qr-dialog ref="qr"></qr-dialog>
    <el-dialog :visible.sync="isShow" width="780">
      <el-table
        :data="getProjectList"
        style="width: 100%">
        <el-table-column
          prop="project_id"
          label="配置名称">
        </el-table-column>
        <el-table-column
          prop="updated_time"
          label="更新时间">
        </el-table-column>
        <el-table-column
          prop="is_checked"
          label="状态">
          <template slot-scope="scope">
            <el-switch
              v-model="scope.row.is_checked"
              :active-value="1"
              :inactive-value="0"
              @change="e => onStatusChange(scope.row.is_checked, scope.row.project_id)">
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column
          fixed="right"
          label="操作"
          width="100">
          <template slot-scope="scope">
            <el-button @click="remove(scope.row.id)" type="text" size="small">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
import RealTimeView from "./RealTimeView.vue";
import SaveDialog from "@/components/SaveDialog";
import QrDialog from "@/components/QrDialog";
import { mapGetters, mapMutations } from "vuex";
import jrQrcode from 'jr-qrcode'
import { editProject, changeChecked, removeProject, getDiyCfgList } from "@/api/project";

export default {
  components: {
    RealTimeView,
    SaveDialog,
    QrDialog,
  },

  provide() {
    return {
      topBar: this,
    };
  },

  data() {
    return {
      show: false,
      currProject: '',
      isShow: false
    };
  },

  watch: {
    'project': {
      immediate: true,
      deep: true,
      handler(v, oldV) {
        console.log(v , 222)
        if (!this.currProject && v.project_id) {
          this.currProject = v.project_id
        }
      },
    }
  },

  computed: {
    ...mapGetters(["project", "userInfo", 'getProjectList']),
  },


  created () {
  },

  methods: {
    ...mapMutations(["logout"]),

    // 返回商城管理
    back() {
      this.$router.push({ name: "managet" });
    },

    onChange (e) {
      const project = this.getProjectList.filter(v => v.project_id === e)[0]
      this.$store.commit('setProject', {
        ...JSON.parse(project.diy_data),
        project_id: e
      })
    },

    remove (id) {
      this.$confirm("是否删除该配置?", "温馨提示", {
        type: "warning",
      }).then(() => {
        removeProject({
          id: id
        }).then(e => {
          if (e.status === 200) {
            this.$message.success('删除成功')
            this.updataPorjectList()
          } else {
            this.$message.error(e.mess)
          }
        })
      }).catch(()=>{
      })
    },

    onStatusChange (e, project_id) {
      changeChecked({
        project_id: project_id,
        is_checked: e
      }).then(e => {
        if (e.status === 200) {
          this.$message.success('操作成功')
          this.updataPorjectList()
        } else {
          this.$message.error(e.mess)
        }
      })
    },

    openSave(view = false) {
      this.$refs.save.open(view);
    },

    toSchema() {
      let { href } = this.$router.resolve({
        path: "/schema",
      });
      window.open(href);
    },

    viewQr() {
      this.$confirm("如不更新，则预览为上次保存的项目数据?", "是否更新保存", {
        confirmButtonText: "更新保存",
        cancelButtonText: "不更新",
        type: "warning",
      }).then(() => {
        this.openSave(true);
      }).catch(()=>{
        this.openQr()
      })
    },

    openQr() {
      this.$refs.qr.open();
    },

    async updataPorjectList () {
      const configList = await getDiyCfgList()
      this.$store.commit('setProjectList', configList.data)
    },

    // 保存
    async save() {
      let data = {
        id: this.project.id,
        userId: this.userInfo.userId,
        name: this.project.project_id,
        richText: JSON.stringify(this.project),
      };

      let { status } = await editProject(data);

      if (status === 200) {
        this.$message.success('保存成功')
        this.updataPorjectList()
      }
      this.updataPorjectList()
    },

    // 退出
    onLogout() {
      this.$confirm("是否确定退出平台?", "退出", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.logout();
        this.$router.push({ name: "login" });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.topBar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 56px;
  width: 100%;
  padding: 0 20px;
  background: #fff;
  border-bottom: 1px solid #ebedf0;

  .topBar-right {
    display: flex;
    align-items: center;

    span {
      flex-shrink: 1;
      color: #999;
      font-size: 12px;
      word-break: keep-all;
    }
  }
}
</style>
